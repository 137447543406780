.progress-wrap {
  display: flex;
  justify-content: center;
  height: 50vh;
  padding-top: 30px;
}

.banner-swiper {
  height: 500px;
  padding-bottom: 20px;
  margin-bottom: 70px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
}

.banner-info-wrap {
  width: 100%;
  height: 100%;
  background-color: #00000049;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.banner-img {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px);
}

.banner-title {
  max-width: 700px;
  width: 100%;
  color: #fff;
  padding: 10px 20px;
}

.banner-more-btn {
  padding: 0 !important;
}

.banner-more-link {
  padding: 6px 20px;
  color: #fff;
  text-decoration: none;
}

.banner-more-link:hover {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .banner-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 475px) {
  .banner-swiper {
    height: 400px;
  }

  .banner-title {
    font-size: 24px;
  }

  .banner-more-link {
    padding: 4px 15px;
  }
}

@media only screen and (max-width: 475px) {
  .banner-swiper {
    height: 300px;
  }

  .banner-title {
    font-size: 20px;
  }

  .banner-more-link {
    font-size: 12px;
    padding: 3px 10px;
  }
}
