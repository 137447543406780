.photos-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.photos-list {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-evenly;
  flex-wrap: wrap;
  grid-gap: 25px 20px;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  margin-right: 20px;
  padding-left: 0;
  list-style: none;
}

.photos-item {
  position: relative;
  max-width: 400px;
  width: 100%;
}

.photos-item:hover .photos-item-inner {
  opacity: 1;
}

.photos-item-inner {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 4px;
  background-color: #0000009c;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.photos-img {
  max-width: 400px;
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.photos-link {
  position: absolute;
  top: 20%;
  left: 38%;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease;
}

.photos-item:hover .photos-link {
  top: 42%;
  z-index: 5;
  opacity: 1;
  pointer-events: all;
}

@media only screen and (max-width: 992px) {
  .photos-list {
    grid-template-columns: repeat(1, auto);
    justify-content: start;
  }
}

@media only screen and (max-width: 768px) {
  .photos-info-wrap {
    flex-direction: column;
  }

  .photos-list {
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 475px) {
  .photos-list {
    grid-template-columns: repeat(1, auto);
  }
}
