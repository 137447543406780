.loader-wrap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.no-data-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.posts-wrap {
  margin-top: 30px;
}

.posts-headline {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  white-space: nowrap;
}

.posts-headline::before {
  display: block;
  width: 100%;
  max-width: 500px;
  height: 2px;
  margin-right: 30px;
  background-color: #000;
  content: "";
}

.posts-headline::after {
  display: block;
  width: 100%;
  max-width: 500px;
  height: 2px;
  margin-left: 30px;
  background-color: #000;
  content: "";
}

.posts-list {
  display: grid;
  grid-template-columns: repeat(3, auto);
  flex-wrap: wrap;
  grid-gap: 30px;
  justify-content: space-between;
  padding-left: 0;
  list-style: none;
}

@media only screen and (max-width: 1200px) {
  .posts-list {
    grid-template-columns: repeat(2, auto);
  }
}

@media only screen and (max-width: 992px) {
  .posts-list {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
  }
}

@media only screen and (max-width: 730px) {
  .posts-list {
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
  }
}

@media only screen and (max-width: 630px) {
  .posts-list {
    grid-template-columns: repeat(1, auto);
    justify-content: space-between;
  }
}
