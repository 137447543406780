/* GENERAL */
*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	height: 100%;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

img {
	display: block;
	height: auto;
}

body {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding: 0;
}

/* VISUALLY-HIDDEN */
.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

/* CONTAINER */
.my-container {
	width: 100%;
	max-width: 1320px;
	margin: 0 auto;
	padding: 0 24px;
}

/* STICKY-FOOTER */
.site-main {
	flex-grow: 1;
}
