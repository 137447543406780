.certificates-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.certificates-info-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}

.certificates-title {
  margin-top: 20px;
  margin-bottom: 25px;
}

.certificates-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin-right: 20px;
  padding-left: 0;
}

.certificates-item {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
}

.certificates-img {
  object-fit: cover;
  max-width: 900px;
  width: auto;
}

.certificates-img-wrap {
  position: relative;
  cursor: pointer;
}

.certificates-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #00000082;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.certificates-img-wrap:hover .certificates-overlay {
  opacity: 1;
}

.certificates-img-wrap:hover .certificates-dowload-wrap {
  opacity: 1;
  pointer-events: all;
}

.certificates-dowload-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.certificates-no-file-text {
  color: #fff;
  font-size: 25px;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .certificates-img {
    max-width: 700px;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .certificates-wrap {
    flex-direction: column;
  }

  .certificates-list {
    margin-right: 0;
  }
}
