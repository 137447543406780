.skeleton-list {
	display: grid;
	grid-template-columns: repeat(3, 300px);
	justify-content: space-between;
	grid-gap: 0px 40px;
	width: 1000px;
	margin-bottom: 50px;
	padding-left: 0;
	list-style-type: none;
	max-width: 300px;
}

.skeleton-item {
	max-width: 300px;
	width: 100%;
}
