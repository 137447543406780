.certificate-file-input-list {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-top: 30px;
  margin-bottom: 40px;
}

.certificate-file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #4245a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.certificate-file-input__label svg {
  height: 16px;
  margin-right: 4px;
}
