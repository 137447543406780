.about-author-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.author-swiper {
  max-width: 500px;
  padding-bottom: 30px;
}

.about-author-img {
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 0 0 20px 20px;
}

.other-about-author-img {
  width: 100%;
  max-width: 100%;
  max-height: 600px;
  object-fit: cover;
  margin-top: 10px;
}

.about-author-title {
  margin-top: 20px;
}

.about-author-descr {
  white-space: pre-wrap;
}

.about-author-back-btn-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}
