.blog-card {
  position: relative;
  display: flex;
  max-width: 1000px;
  width: 100%;
  margin-bottom: 50px;
  padding-bottom: 10px;
  border-bottom: 1px solid #9999998b;
}

.blog-img {
  max-width: 350px;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.blog-info {
  padding: 0px 20px 20px 20px;
}

.blog-category-text {
  display: block;
  margin-bottom: 15px;
  font-size: 18px;
  text-decoration: none;
  color: dodgerblue;
}

.blog-category-text:hover {
  color: dodgerblue;
  text-decoration: underline;
}

.blog-more-btn {
  padding: 0 !important;
}

.blog-more-link {
  padding: 6px 20px;
  text-decoration: none;
}

.blog-more-link:hover {
  color: #0d6efd;
}

.blog-time {
  position: absolute;
  right: 20px;
  bottom: 0px;
}

@media only screen and (max-width: 1400px) {
  .blog-category-text {
    font-size: 16px;
  }

  .blog-title {
    font-size: 24px;
  }

  .blog-descr {
    font-size: 16px;
  }

  .blog-more-link {
    padding: 5px 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .blog-img {
    max-width: 300px;
    height: 280px;
  }

  .blog-category-text {
    font-size: 14px;
  }

  .blog-title {
    font-size: 20px;
  }

  .blog-more-link {
    padding: 4px 14px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 992px) {
  .blog-card {
    flex-direction: column;
  }

  .blog-img {
    max-width: 100%;
    height: 300px;
  }

  .blog-info {
    margin-top: 10px;
    padding: 0px 20px 20px 0px;
  }
}

@media only screen and (max-width: 475px) {
  .blog-descr {
    font-size: 14px;
  }
}
