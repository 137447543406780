.recommendation-item {
	position: relative;
	max-width: 300px;
	width: 100%;
	max-height: 400px;
	height: 100%;
}

.recommendation-item:not(:last-child) {
	margin-right: 20px;
}

.recommendation-link {
	text-decoration: none;
	color: #000;
	transition: color 0.2s ease;
}

.recommendation-img {
	max-width: 300px;
	width: 100%;
	height: 200px;
	margin-bottom: 10px;
	object-fit: cover;
}

.recommendation-title {
	font-size: 18px;
	margin-bottom: 40px;
}

.recommendation-time {
	position: absolute;
	right: 10px;
	bottom: 0px;
	margin: 0;
}

@media only screen and (max-width: 1400px) {
	.recommendation-item {
		max-width: 250px;
	}
}

@media only screen and (max-width: 1200px) {
	.recommendation-item {
		max-width: 200px;
	}

	.recommendation-title {
		font-size: 16px;
	}
}

@media only screen and (max-width: 992px) {
	.recommendation-item {
		max-width: 350px;
	}

	.recommendation-img {
		max-width: 350px;
	}
}

@media only screen and (max-width: 576px) {
	.recommendation-item {
		max-width: 400px;
	}

	.recommendation-item:not(:last-child) {
		margin-right: 0;
	}

	.recommendation-img {
		max-width: 400px;
		height: 240px;
	}
}
