.admin-home-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
}

.admin-home-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid rgb(28, 9, 121);
}

.admin-home-username,
.admin-home-text {
  text-align: center;
}

@media only screen and (max-width: 730px) {
  .admin-home-wrap {
    height: 80vh;
  }
}
