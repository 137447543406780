.news-wrap {
  display: flex;
  margin-top: 20px;
}

.news-info-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.post-no-data-text {
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
}

.blog-list {
  width: 100%;
  margin-right: 20px;
  padding-left: 0;
}

.progress-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 150px;
}

@media only screen and (max-width: 768px) {
  .news-info-wrap {
    flex-direction: column;
  }

  .blog-list {
    margin-right: 0px;
  }
}
