.test-mode {
  padding: 8px 0;
  background-color: #2f85da;
}

.test-mode-text {
  color: #ffffffe8;
  text-align: center;
  margin: 0;
}

@media only screen and (max-width: 576px) {
  .test-mode {
    font-size: 14px;
  }
}
