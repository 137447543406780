/* FONTS */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: url("../../assets/fonts/roboto-v30-latin-regular.woff2") format("woff2");
}
/* roboto-italic - latin */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: url("../../assets/fonts/roboto-v30-latin-italic.woff2") format("woff2");
}
/* roboto-500 - latin */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: url("../../assets/fonts/roboto-v30-latin-500.woff2") format("woff2");
}
/* roboto-500italic - latin */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: italic;
	font-weight: 500;
	src: url("../../assets/fonts/roboto-v30-latin-500italic.woff2")
		format("woff2");
}
/* roboto-700 - latin */
@font-face {
	font-display: swap;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: url("../../assets/fonts/roboto-v30-latin-700.woff2") format("woff2");
}

.home {
	font-family: "Roboto";
	background-color: #eeeeeec2;
}
