.posts-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  height: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.posts-time {
  position: absolute;
  top: 8px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 700;
  right: 8px;
  display: block;
  padding: 2px 10px;
  background-color: #ffdb0c;
  color: #2e2e2e;
}

.posts-img {
  width: 100%;
  max-width: 350px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}

.posts-info {
  padding: 20px 20px 0 20px;
}

.posts-control {
  display: flex;
  justify-content: end;
  padding: 0px 20px 20px 20px;
}

.posts-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.posts-author {
  display: block;
  margin-bottom: 20px;
}

@media only screen and (max-width: 730px) {
  .posts-info {
    padding: 15px 15px 0 15px;
  }

  .posts-title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .posts-descr {
    font-size: 15px;
  }
}

@media only screen and (max-width: 630px) {
  .posts-item {
    max-width: 100%;
  }

  .posts-img {
    max-width: 100%;
    height: 400px;
  }
}

@media only screen and (max-width: 525px) {
  .posts-img {
    height: 300px;
  }
}

@media only screen and (max-width: 375px) {
  .posts-img {
    max-width: 100%;
    height: 200px;
  }
}
