.user-info-card {
	position: sticky;
	top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-shrink: 0;
	max-width: 262px;
	width: 100%;
	max-height: 500px;
	text-align: center;
	padding: 40px 15px;
	background-color: rgb(1, 78, 155);
	border-radius: 10px;
}

.user-img {
	max-width: 220px;
	width: 100%;
	border: 2px solid #fff;
}

.username-text {
	margin-top: 15px;
	margin-bottom: 10px;
	color: #ffffff;
	white-space: nowrap;
}

.user-descr {
	margin-bottom: 50px;
	color: #ffffffa3;
}

.user-info-btn {
	padding: 0 !important;
}

.user-info-link {
	padding: 6px 20px;
	text-decoration: none;
	color: #ffffffce;
	transition: color 0.3s ease;
}

.user-info-link:hover {
	color: #fff;
}

@media only screen and (max-width: 992px) {
	.user-info-card {
		max-width: 220px;
		max-height: 400px;
	}

	.username-text {
		font-size: 24px;
	}

	.user-descr {
		margin-bottom: 20px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 768px) {
	.user-info-card {
		max-width: 250px;
		max-height: 440px;
	}
}
