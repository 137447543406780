@font-face {
  font-display: swap;
  font-family: "Arizonia";
  font-weight: 400;
  src: url("../../assets/fonts/arizonia-v19-latin-regular.woff2")
    format("woff2");
}

.hero {
  width: 100%;
  padding: 145px 0;
  background-image: url("../../assets/images/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site-title {
  font-family: "Arizonia", cursive;
  margin: 0;
  font-size: 90px;
  color: #fff;
}

.hero-descr {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: #fffffff1;
}

.hero-descr::before {
  display: block;
  width: 30px;
  height: 2px;
  margin-right: 8px;
  background-color: #e2e2e2;
  content: "";
}

.hero-descr::after {
  display: block;
  width: 30px;
  height: 2px;
  margin-left: 8px;
  background-color: #e2e2e2;
  content: "";
}

.hero-social-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  gap: 30px;
  padding-left: 0;
}

.hero-social-link {
  font-size: 27px;
  color: #e2e2e2;
  transition: 0.3s ease;
}

.hero-social-link:hover {
  color: gold;
}

@media only screen and (max-width: 768px) {
  .site-title {
    font-size: 70px;
  }

  .hero-descr {
    font-size: 16px;
  }

  .hero-social-link {
    font-size: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .site-title {
    font-size: 50px;
  }

  .hero-descr {
    font-size: 16px;
  }

  .hero-social-link {
    font-size: 22px;
  }
}

@media only screen and (max-width: 422px) {
  .site-title {
    font-size: 40px;
  }

  .hero-descr {
    font-size: 14px;
  }

  .hero-social-link {
    font-size: 22px;
  }
}

@media only screen and (max-width: 375px) {
  .site-title {
    font-size: 35px;
  }

  .hero-descr {
    font-size: 12px;
  }

  .hero-social-link {
    font-size: 18px;
  }
}
