.single-wrap {
	display: flex;
	justify-content: space-between;
}

.single-info {
	width: 100%;
	max-width: 1000px;
	margin-right: 20px;
}

.mySwiper {
	padding-bottom: 40px;
}

.swiper-pagination {
	bottom: 0px !important
	;
}

.single-img {
	width: 100%;
	max-width: 1000px;
	height: 470px;
	border-radius: 0 0 15px 15px;
	object-fit: cover;
}

.single-title {
	text-align: center;
	margin-bottom: 15px;
}

.single-descr {
	margin-bottom: 20px;
	font-size: 18px;
	white-space: pre-wrap;
}

.single-author-name {
	display: block;
	margin-bottom: 10px;
}

.single-time {
	font-size: 20px;
	font-weight: 700;
}

.back-btn-wrap {
	display: flex;
	justify-content: flex-end;
}

.back-btn {
	margin-top: 50px;
	margin-bottom: 30px !important;
}

@media only screen and (max-width: 768px) {
	.single-wrap {
		flex-direction: column;
	}

	.single-img {
		height: 400px;
	}

	.single-title {
		font-size: 24px;
	}

	.single-descr {
		font-size: 16px;
	}
}
