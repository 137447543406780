.admin-video-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.admin-video-title {
  font-size: 22px;
}

.admin-video-text-wrap {
  padding: 10px 20px 0 20px;
}

.admin-video-text {
  margin-bottom: 30px;
}
