.login-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.login-error .login {
  box-shadow: 0px 11px 15px -7px rgba(230, 6, 6, 0.2),
    0px 24px 38px 3px rgba(255, 0, 0, 0.14),
    0px 9px 46px 8px rgba(255, 0, 0, 0.588) !important;
}

.login {
  width: 50%;
  padding: 50px;
  margin: 0 auto;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
}

.password-show-btn-wrap {
  font-size: 22px;
  cursor: pointer;
}

.login-error-text {
  margin-top: 0;
  text-align: center;
  color: #e70f0f;
}

@media only screen and (max-width: 992px) {
  .login {
    width: 80%;
  }
}

@media only screen and (max-width: 700px) {
  .login {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .login {
    width: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .login {
    padding: 40px 20px;
  }
}
