.admin-certificates-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 350px;
  height: 100%;
  border-bottom: 1px solid #222;
  border-radius: 8px;
}

.admin-certificates-img-wrap {
  max-height: 400px;
  height: 100%;
}

.admin-certificates-img {
  max-width: 350px;
  width: 100%;
  max-height: 300px;
  height: 100%;
  object-fit: cover;
}

.admin-certificates-control {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
