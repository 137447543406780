.videos-info-wrap {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.videos-list {
	width: 100%;
	margin-right: 20px;
	padding-left: 0;
	list-style-type: none;
}

.videos-item {
	display: flex;
	width: 100%;
	max-width: 1000px;
}

.videos-item:not(:last-child) {
	margin-bottom: 40px;
}

.videos-wrap {
	width: 100%;
	max-width: 500px;
	margin-right: 20px;
}

.videos-info {
	width: 100%;
	max-width: 400px;
}

@media only screen and (max-width: 1200px) {
	.videos-info {
		max-width: 300px;
	}

	.videos-title {
		font-size: 24px;
	}

	.videos-descr {
		font-size: 14px;
	}
}

@media only screen and (max-width: 992px) {
	.videos-item {
		display: flex;
		flex-direction: column;
	}

	.videos-item:not(:last-child) {
		margin-bottom: 50px;
	}

	.videos-info {
		max-width: 100%;
	}

	.videos-title {
		font-size: 24px;
	}

	.videos-descr {
		font-size: 14px;
	}
}

@media only screen and (max-width: 768px) {
	.videos-info-wrap {
		flex-direction: column;
	}

	.videos-list {
		margin-right: 0;
		margin-bottom: 50px;
	}

	.videos-info {
		max-width: 100%;
	}

	.videos-title {
		font-size: 24px;
	}

	.videos-descr {
		font-size: 14px;
	}
}
