.blog-list-card {
	display: flex;
	max-width: 1000px;
	width: 100%;
	margin-bottom: 50px;
	padding-bottom: 10px;
	border-bottom: 1px solid #9999998b;
}

.blog-list-img {
	max-width: 350px;
	width: 100%;
	height: 300px;
	object-fit: cover;
}

.blog-list-info {
	position: relative;
	width: 100%;
	padding: 0px 20px 20px 20px;
}

.blog-list-category-text {
	display: block;
	margin-bottom: 15px;
	font-size: 18px;
	text-decoration: none;
	color: dodgerblue;
}

.blog-list-category-text:hover {
	color: dodgerblue;
	text-decoration: underline;
}

.blog-list-more-btn {
	padding: 0 !important;
}

.blog-list-more-link {
	padding: 6px 20px;
	text-decoration: none;
}

.blog-list-more-link:hover {
	color: #0d6efd;
}

.blog-list-time {
	position: absolute;
	right: 20px;
	bottom: 0px;
}

@media only screen and (max-width: 1200px) {
	.blog-list-img {
		max-width: 300px;
		height: 280px;
	}

	.blog-list-title {
		font-size: 20px;
	}

	.blog-list-more-link {
		padding: 4px 14px;
		font-size: 12px;
	}
}

@media only screen and (max-width: 992px) {
	.blog-list-card {
		flex-direction: column;
	}

	.blog-list-img {
		max-width: 100%;
		height: 300px;
	}

	.blog-list-info {
		margin-top: 10px;
		padding: 0px 20px 20px 0px;
	}
}

@media only screen and (max-width: 768px) {
	.home-wrapper {
		flex-direction: column-reverse;
	}

	.home-info-wrap {
		margin-right: 0px;
	}

	.home-author-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
